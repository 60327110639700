<template>
  <v-container>
    <v-row :align="'center'" :justify="'center'">
      <v-stepper v-model="e1" style="max-width: 900px">
        <v-stepper-header>
          <v-stepper-step step="1" @click="e1 = 1">
            Общая информация
          </v-stepper-step>

          <v-divider />

          <v-stepper-step step="2" @click="e1 = 2"> Реквизиты </v-stepper-step>

          <v-divider />

          <v-stepper-step step="3" @click="e1 = 3"> Условия </v-stepper-step>

          <v-divider />

          <v-stepper-step step="4" @click="e1 = 4"> Блоки </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row>
              <v-col class="pt-2 pb-0" cols="12">
                <v-text-field
                  dense
                  label="Наименование"
                  v-model="post_data.title"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-text-field dense label="Город" v-model="post_data.city" />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-text-field
                  dense
                  label="Контактное лицо"
                  v-model="post_data.contact_person"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-text-field
                  dense
                  label="Телефон"
                  v-model="post_data.phone_number"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-text-field dense label="E-mail" v-model="post_data.email" />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-select
                  v-model="post_data.channel_type"
                  :items="channel_types"
                  label="Тип"
                />
              </v-col>
            </v-row>
            <v-row v-if="post_data.channel_type == 'external'">
              <v-col cols="4" sm="4" md="4">
                <v-autocomplete
                  :search-input.sync="search"
                  :items="channels"
                  v-model="post_data.wubook_id"
                  label="Выбрать канал"
                  placeholder="(по названию)"
                  item-text="tag"
                  item-value="id"
                />
                <!-- qaztrip_id -->
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="4" sm="4" md="4">
                <v-autocomplete
                  :search-input.sync="search"
                  :items="operators"
                  v-model="post_data.qaztrip_id"
                  @change="handleChange"
                  label="Выбрать туроператора"
                  placeholder="(по названию)"
                  item-text="name"
                  item-value="id"
                />
                <!-- qaztrip_id -->
              </v-col>
            </v-row>

            <v-btn color="primary" @click="nextStep"> Далее </v-btn>

            <v-btn to="/" text> Выход </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-row>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-text-field dense label="ИП (ФИО)" v-model="post_data._ie" />
              </v-col>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-text-field
                  dense
                  label="Адрес регистрации"
                  v-model="post_data.legal_address"
                />
              </v-col>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-text-field
                  dense
                  label="Почтовый адрес"
                  v-model="post_data.post_code"
                />
              </v-col>
              <v-col class="pt-2 pb-0" cols="12" md="12">
                <v-text-field
                  dense
                  label="БИК банка"
                  v-model="post_data._bik"
                />
              </v-col>
            </v-row>
            <v-btn color="primary" @click="nextStep"> Далее </v-btn>

            <v-btn @click="prevStep" text> Назад </v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-row>
              <v-col class="pt-2 pb-0" cols="12">
                <v-select
                  dense
                  :items="commission_types"
                  label="Тип комиссии"
                  v-model="post_data.commission_type"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-text-field
                  dense
                  type="number"
                  label="Комиссия"
                  v-model="post_data.commission"
                  :append-icon="
                    post_data.commission_type == 'percent' ? '%' : ''
                  "
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(post_data.due_date)"
                      label="Сроки оплаты"
                      prepend-icon="mdi-calendar-blank-multiple"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    :first-day-of-week="1"
                    class="mt-0 mb-0"
                    v-model="post_data.due_date"
                  />
                </v-menu>
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-checkbox
                  dense
                  label="Просмотр календаря"
                  v-model="post_data.calendar_view"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-checkbox
                  dense
                  label="Открытый на продажу"
                  v-model="post_data.is_open"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-select
                  dense
                  :items="booking_forms"
                  label="Форма бронирования"
                  v-model="post_data.booking_form"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-select
                  dense
                  :items="limit_types"
                  label="Лимиты на продажу"
                  v-model="post_data.sales_limit_type"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-text-field
                  dense
                  type="number"
                  label="Значение лимита на продажу"
                  v-model="post_data.sales_limit_value"
                />
              </v-col>
              <v-col class="pt-0 pb-0" cols="12">
                <v-text-field
                  dense
                  type="number"
                  label="Минимальный промежуток между бронями"
                  v-model="post_data.min_days_between_bookings"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="primary" @click="nextStep"> Далее </v-btn>

                <v-btn @click="prevStep" text> Назад </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="primary" @click="createSource"> Сохранить </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="4">
            <blocks
              :saveBlocks="createSource"
              :disabledBtn="disabledBtn"
            ></blocks>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-row>
  </v-container>
</template>

<script>
import toast from "../../../plugins/toast";
import user from "../../../store/auth";
import blocks from "../../main/components/Blocks";
import Blocks from "../components/Blocks.vue";
import moment from "moment";
import { SalesApiUrls } from "@/services/accommodationRequests/sales.api.js";

export default {
  name: "CreateSource",
  components: {
    blocks
  },
  data() {
    return {
      disabledBtn: false,
      e1: 1,
      operators: [],
      channels: [],
      channel_types: [
        {
          text: "Easybook - Собственная продажа",
          value: "easybook"
        },
        {
          text: "Виджет",
          value: "widget"
        },
        {
          text: "Go2Trip",
          value: "go2trip"
        },
        {
          text: "Qaztrip",
          value: "qaztrip"
        },
        {
          text: "External",
          value: "external"
        }
      ],
      booking_forms: [
        {
          text: "Календарь",
          value: "grid"
        },
        {
          text: "Заявка",
          value: "request"
        }
      ],
      limit_types: [
        {
          text: "%",
          value: "percent"
        },
        {
          text: "Количество",
          value: "quantity"
        }
      ],
      commission_types: [
        {
          text: "Проценты",
          value: "percent"
        },
        {
          text: "Фиксированное",
          value: "fixed"
        }
      ],
      queryTerm: "",
      post_data: {
        channel_type: "easybook",
        title: "",
        city: "",
        contact_person: "",
        phone_number: "",
        email: "",
        _ie: "",
        _bik: "",
        legal_address: "",
        post_code: "",
        commission: 10,
        due_date: "",
        calendar_view: false,
        booking_form: "request",
        status_permission: "reserve",
        sales_limit_type: "percent",
        sales_limit_value: 10,
        min_days_between_bookings: 0,
        tour_operator: null,
        company: Number(user.getters.getCompany),
        blocks: []
      }
    };
  },
  computed: {
    search: {
      get() {
        return this.queryTerm;
      },
      set(searchInput = "") {
        if (this.queryTerm !== searchInput) {
          this.queryTerm = searchInput;
          this.getOperators();
          this.getChannels();
        }
      }
    }
  },
  async created() {
    this.getChannels();
    this.getOperators(this.queryTerm);
    if (this.$route.query.id) {
      this.post_data = await SalesApiUrls.getSale(this.$route.query.id);
      this.post_data.due_date = moment(
        new Date(+this.post_data.due_date * 1000)
      ).format("YYYY-MM-DD");
    }
  },
  methods: {
    handleChange(value) {
      let obj = {};
      this.operators.forEach(e => {
        if (e.id == value) obj = e;
      });
      this.post_data = { ...this.post_data, ...obj, title: obj.name };
    },
    formatDate(date) {
      if (date) return moment(date).format("DD.MM.YYYY");
      return;
    },
    async createSource(blocks = []) {
      let due_date = new Date(this.post_data.due_date).getTime() / 1000;
      if (this.$route.query.id) {
        try {
          await SalesApiUrls.setSale(this.$route.query.id, {
            ...this.post_data,
            due_date
          });
          toast.success("Успешно изменено!");
          return;
        } catch (e) {
          console.log(e);
          return;
        }
      }

      let post_continue = true;
      this.post_data.blocks = blocks;
      if (this.post_data.blocks.length && this.post_data.blocks.length > 0) {
        this.post_data.blocks.map(el => {
          if (el.category === null) {
            toast.error(
              "Заполните все поля в блоках или удалите не нужный блок"
            );
            post_continue = false;
          }
        });
      }
      if (!post_continue) {
        return;
      }
      try {
        let resp = await SalesApiUrls.createSale({
          ...this.post_data,
          due_date
        });
        toast.success("Успешно создан!");
        let blocks_upd = [];
        if (this.post_data.blocks.length && this.post_data.blocks.length > 0) {
          this.post_data.blocks.forEach(e => {
            if (e.rooms.length > 0) {
              e.rooms.forEach(j => {
                let check_in, check_out;
                if (localStorage.getItem("check_in_time"))
                  check_in =
                    e.check_in + " " + localStorage.getItem("check_in_time");
                else check_in = e.check_in;

                if (localStorage.getItem("check_out_time"))
                  check_out =
                    e.check_out + " " + localStorage.getItem("check_out_time");
                else check_out = e.check_out;

                blocks_upd.push({
                  room: j,
                  sales_channel: resp.id,
                  check_in: new Date(check_in).getTime() / 1000,
                  check_out: new Date(check_out).getTime() / 1000
                });
              });
            }
          });
        }
        await SalesApiUrls.createBlock(blocks_upd);
        toast.success("Успешно добавлены блоки!");
      } catch (e) {
        console.log(e);
      }
    },
    nextStep(step) {
      if (this.$route.query.id && this.e1 == 3) {
      } else {
        this.e1 = this.e1 + 1;
      }
    },
    nextStepUpd() {
      if (!this.$route.query.id) this.e1 = 4;
    },
    prevStep() {
      this.e1 = this.e1 - 1;
    },
    async getOperators() {
      let params = {
        search: this.queryTerm
      };
      let res = await SalesApiUrls.getOperators(params);
      this.operators = res;
    },
    async getChannels() {
      let res = await SalesApiUrls.getChannels();
      this.channels = res;
    }
  }
};

Blocks;
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
